import { Button } from "react-bootstrap";
const Object = (props) => {
  return (
    <>
      <div className="col-6">
        <div className="row" style={{ width: "100%" }}>
          <img
            src={props.image}
            alt="A la française"
            style={{
              maxWidth: "300px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
        <div className="row mt-3" style={{ height: "4rem" }}>
          <h4>{props.title}</h4>
        </div>
        {props.desc != null ? (
          <>
            <div
              className="row"
              style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }}
            >
              <p style={{ textAlign: "justify" }}>{props.desc}</p>
            </div>
          </>
        ) : (
          <></>
        )}

        {props.att != null ? (
          <>
            <div className="row mb-3" style={{ height: "2rem" }}>
              <div className="col">
                <Button
                  style={{
                    width: "100px",
                    marginLeft: "auto",
                    marginRight: "1rem",
                  }}
                  href={props.att}
                  target="blank"
                >
                  File
                </Button>
                {props.att2 != null ? (
                  <Button
                    style={{
                      width: "100px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    href={props.att2}
                    target="blank"
                  >
                    File
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Object;

import Event from "../component/portofolio/Event";
import Sosmed from "../component/portofolio/Sosmed";
import Translate from "../component/portofolio/Translate";
import Disability from "../component/portofolio/Disability";
import Default from "../component/portofolio/Default";
import { FaArrowRight } from "react-icons/fa";
import { useState } from "react";

const Portofolio = () => {
  const [section, setSection] = useState(0);
  return (
    <>
      <div className="row mt-5" style={{ backgroundColor: "pink" }}>
        <div
          className="container mt-5 mb-5"
          style={{ backgroundColor: "pink" }}
        >
          <h2 style={{ backgroundColor: "pink" }}>Recent Work</h2>
        </div>
      </div>
      <div className="container mt-3">
        {section == 0 ? (
          <>
            <Default section={setSection} />
          </>
        ) : (
          <>
            {section == 1 ? (
              <>
                <Event />
              </>
            ) : (
              <>
                {section == 2 ? (
                  <>
                    <Sosmed />
                  </>
                ) : (
                  <>
                    {section == 3 ? (
                      <>
                        <Translate />
                      </>
                    ) : (
                      <>
                        {section == 4 ? (
                          <>
                            <Disability />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <div className="row" style={{ textAlign: "end", width: "100%" }}>
          {section == 1 ? (
            <>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSection(section + 1);
                }}
              >
                Social Media and Creative Content <FaArrowRight />
              </p>
            </>
          ) : (
            <>
              {section == 2 ? (
                <>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSection(section + 1);
                    }}
                  >
                    Translate Project
                    <FaArrowRight />
                  </p>
                </>
              ) : (
                <>
                  {section == 3 ? (
                    <>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSection(section + 1);
                        }}
                      >
                        Disability Rights, Gender, and Social Inclusivity{" "}
                        <FaArrowRight />
                      </p>
                    </>
                  ) : (
                    <>
                      {section == 4 ? (
                        <>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSection(1);
                            }}
                          >
                            Event Organizer <FaArrowRight />
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Portofolio;

import { FaStar } from "react-icons/fa";
const Service = () => {
  return (
    <>
      <div className="container mt-5">
        <div
          style={{
            display: "flex",
            columnGap: "0.5rem",
            backgroundColor: "transparent",
          }}
        >
          <FaStar
            style={{
              backgroundColor: "transparent",
              fontWeight: "500",
              fontSize: "1.5rem",
              color: "black",
            }}
          />
          <div style={{ backgroundColor: "transparent" }}>
            <p
              style={{
                color: "black",
                fontSize: "1.5rem",
                fontWeight: "500",
                lineHeight: "20px",
                marginTop: "3px",
                backgroundColor: "transparent",
              }}
            >
              Skills
            </p>
          </div>
        </div>
        <div style={{ textAlign: "start", marginLeft: "2rem" }}>
          <li style={{ fontSize: "1.25rem" }}>Language</li>
          <ol style={{ marginLeft: "2rem" }}>
            <li style={{ fontSize: "1.25rem" }}>
              Indonesia: native, UKBI 688/800
            </li>
            <li style={{ fontSize: "1.25rem" }}>French: CEFR B2</li>
            <li style={{ fontSize: "1.25rem" }}>English: Duolingo 125/160</li>
            <li style={{ fontSize: "1.25rem" }}>
              Bahasa isyarat Indonesia tingkat 1
            </li>
          </ol>
          <li style={{ fontSize: "1.25rem" }}>Writing</li>
          <ol style={{ marginLeft: "2rem" }}>
            <li style={{ fontSize: "1.25rem" }}>
              Essay, Creative and Analytical Writing
            </li>
            <li style={{ fontSize: "1.25rem" }}>
              Content Writing and Copywriting for Instagram
            </li>
          </ol>
          <li style={{ fontSize: "1.25rem" }}>Editing</li>
          <ol style={{ marginLeft: "2rem" }}>
            <li style={{ fontSize: "1.25rem" }}>Canva</li>
            <li style={{ fontSize: "1.25rem" }}>Capcut</li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Service;

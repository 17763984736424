import Profile from "../../assets/profile.jpg";
const Default = (props) => {
  return (
    <>
      <div className="row">
        <h1>Some text</h1>
        <div
          className="col me-5"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.section(1);
          }}
        >
          <img src={Profile} alt="gambar1" />
          <p>Event Organizer</p>
        </div>
        <div
          className="col me-5"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.section(2);
          }}
        >
          <img src={Profile} alt="gambar1" />
          <p>Social Media and Creative Content</p>
        </div>
        <div
          className="col me-5"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.section(3);
          }}
        >
          <img src={Profile} alt="gambar1" />
          <p>Translation Project</p>
        </div>
        <div
          className="col me-5"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.section(4);
          }}
        >
          <img src={Profile} alt="gambar1" />
          <p>Disability Rights, Gender, and Social Inclusivity</p>
        </div>
      </div>
    </>
  );
};

export default Default;

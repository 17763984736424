import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaCaretDown } from "react-icons/fa";
import "./Navbar.css";
import { useEffect, useState } from "react";
import { FaMoon, FaSun } from "react-icons/fa";

const NavigationBar = ({ page }) => {
  return (
    <>
      <Navbar
        id="nav"
        expand="lg"
        className="shadow p-3"
        style={{
          padding: "20px",
          position: "sticky",
          top: "0",
          zIndex: "1",
          width: "100%",
        }}
      >
        <Container>
          <Navbar.Brand href="/" style={{ fontWeight: "500" }}>
            Gerarda Mayella
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{
              marginLeft: "25%",
            }}
          >
            <Nav
              className="ml-auto "
              style={{
                alignSelf: "center",
                marginRight: "auto",
                fontSize: "0.875rem",
              }}
            >
              <Nav.Link
                id="nav-home"
                href="#home"
                onClick={() => {
                  page(1);
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                id="nav-resume"
                href="#resume"
                onClick={() => {
                  page(2);
                }}
              >
                Résumé
              </Nav.Link>
              <Nav.Link
                id="nav-portofolio"
                href="#portofolio"
                onClick={() => {
                  page(3);
                }}
              >
                Portofolio
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;

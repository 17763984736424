import Object from "./Object";
import Profile from "../../assets/profile.jpg";
const Disablity = () => {
  return (
    <>
      <div className="mb-5">
        <div className="row">
          <h3 style={{ textAlign: "start" }}>
            Disability Rights, Gender, and Social Inclusivity
          </h3>
        </div>
        <div className="container p-3">
          <div className="row">
            <Object
              image={Profile}
              title="Basic Indonesian Sign Language"
              att="https://drive.google.com/file/d/1_PjnIvwI4BZgnjMa0Ls75IcwOxvwhjGP/view"
            />
            <Object image={Profile} title="inclusivity survey" />
            <Object image={Profile} title="Writing" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Disablity;

import Object from "./Object";
import Profile from "../../assets/profile.jpg";
import Orbit1 from "../../assets/Orbit1.png";
import Orbit2 from "../../assets/Orbit2.png";
const Sosmed = () => {
  return (
    <>
      <div className="mb-5">
        <div className="row">
          <h3 style={{ textAlign: "start" }}>
            Social Media and Creative Content
          </h3>
        </div>
        <div className="container p-3">
          <div className="row mb-3" style={{ textAlign: "justify" }}>
            <h4>IFI Yogyakarta</h4>
            <p>
              Double information sharing due to lack of coordination is an issue
              that I noticed in the first week of my internship. In order to
              make it more efficient, I created a content calendar that can be
              used and accessed by all of the team members. This new method
              resulted in more efficient information sharing and decreased
              miscommunication that still be used today. Moreover, I organized,
              researched, and fabricated various contents in order to promote
              IFI Yogyakarta’s cultural event and share interesting trivia about
              France.{" "}
            </p>
          </div>
          <div className="row">
            <Object
              image={Profile}
              title="semaine de la francophonie"
              att="https://pdf.veintisietemgm.com/arda/GerardaMayella_Semaine_de_la_Francophonie.pdf"
            />
            <Object image={Profile} title="Kedai franco-indo" />
            <Object image={Profile} title="Workshop percakapan" />
            <Object image={Profile} title="Trivia sejarah hari musik sedunia" />
            <Object
              image={Profile}
              title="Crafted the scenario, directed talents, and edited the video"
              att="https://pdf.veintisietemgm.com/arda/GerardaMayella_Jeu_de_7_Damilles.pdf"
              att2="GerardaMayella_Content_Plan_Mediatek.pdf"
            />
            <Object image={Profile} title="Edited the video" />
            <Object
              image={Profile}
              title="Tour IFI a collaboration with IF Indonesia"
            />
            <Object
              image={Profile}
              title="Trivia Prancis"
              att="https://pdf.veintisietemgm.com/arda/GerardaMayella_Trivia_Prancis.pdf"
            />
          </div>
          <div className="row mb-3" style={{ textAlign: "justify" }}>
            <h4>Arcet</h4>
            <p>
              In late December 2021, Orbit launched its new liquid variant,
              Banana Baked Walnut. As a growing enterprise that wants to
              establish its market, I created several publications with the main
              purpose of engaging with potential loyal buyers and promoting its
              new product. These projects challenged me to be wild, expressive,
              and bold in writing in order to create out-of-the-box yet related
              content.{" "}
            </p>
          </div>
          <div className="row">
            <img src={Orbit1} alt="orbit1" />
            <img src={Orbit2} alt="orbit2" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sosmed;

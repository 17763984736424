import Object from "./Object";
import Movie from "../../assets/Movie.jpg";
import Profile from "../../assets/profile.jpg";
const Translate = () => {
  return (
    <>
      <div className="mb-5">
        <div className="row">
          <h3 style={{ textAlign: "start" }}>Translation Project</h3>
        </div>
        <div className="container p-3">
          <div className="row">
            <Object
              image={Movie}
              title="Movie translation “Hidup kedua Simon”"
            />
            <Object
              image={Profile}
              title="Text Translation"
              att="https://pdf.veintisietemgm.com/arda/GerardaMayella_patrick_modiano_catherine_certitude_translation.pdf"
              att2="https://pdf.veintisietemgm.com/arda/GerardaMayella_porto_penerjamahan_surat_undangan.pdf"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Translate;

import { FaStar } from "react-icons/fa";
const Service = () => {
  return (
    <>
      <div className="container">
        <div
          style={{
            display: "flex",
            columnGap: "0.5rem",
            backgroundColor: "transparent",
          }}
        >
          <FaStar
            style={{
              backgroundColor: "transparent",
              fontWeight: "500",
              fontSize: "1.5rem",
              color: "black",
            }}
          />
          <div style={{ backgroundColor: "transparent" }}>
            <p
              style={{
                color: "black",
                fontSize: "1.5rem",
                fontWeight: "500",
                lineHeight: "20px",
                marginTop: "3px",
                backgroundColor: "transparent",
              }}
            >
              I can help you with
            </p>
          </div>
        </div>
        <div style={{ textAlign: "start", marginLeft: "2rem" }}>
          <li style={{ fontSize: "1.25rem" }}>Conten Writing</li>
          <li style={{ fontSize: "1.25rem" }}>Copywriting</li>
          <li style={{ fontSize: "1.25rem" }}>Proofreading and Editing</li>
          <li style={{ fontSize: "1.25rem" }}>Translating</li>
        </div>
      </div>
    </>
  );
};

export default Service;

import Tilt from "react-parallax-tilt";
import { Button } from "react-bootstrap";
import Profile from "../assets/profile.jpg";

const Home = () => {
  return (
    <>
      <div
        id="about-container"
        className="container"
        style={{ position: "relative" }}
      >
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 mb-5 align-items-center p-3 mt-5">
            <div className="row mt-5">
              <div className="row mt-5" style={{ width: "100%" }}>
                <h3 style={{ fontWeight: "800" }}>Gerarda Mayella N.K.</h3>
              </div>
              <div className="row mt-3">
                <p style={{ textAlign: "justify" }}>
                  an aspiring writer with an interest in gender equality,
                  disability, and social inclusion.
                </p>
                <hr />
                <p style={{ textAlign: "justify" }}>
                  I am keen on several forms of writing (content writing,
                  copywriting, and creative writing) and am familiar with
                  proofreading and editing documents to make them more
                  comprehensible.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 align-items-center p-5">
            <div style={{ width: "100%", alignItems: "center" }}>
              <Tilt
                style={{
                  maxWidth: "400px",
                  width: "100%",
                  height: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "50%",
                  clipPath: "circle()",
                }}
              >
                <img
                  src={Profile}
                  style={{
                    maxWidth: "500px",
                    width: "100%",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                ></img>
              </Tilt>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

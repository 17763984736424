import { FaStar, FaEnvelope } from "react-icons/fa";
const Service = () => {
  return (
    <>
      <div className="container mt-5">
        <div
          style={{
            display: "flex",
            columnGap: "0.5rem",
            backgroundColor: "transparent",
          }}
        >
          <FaStar
            style={{
              backgroundColor: "transparent",
              fontWeight: "500",
              fontSize: "1.5rem",
              color: "black",
            }}
          />
          <div style={{ backgroundColor: "transparent" }}>
            <p
              style={{
                color: "black",
                fontSize: "1.5rem",
                fontWeight: "500",
                lineHeight: "20px",
                marginTop: "3px",
                backgroundColor: "transparent",
              }}
            >
              Contact Me
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            columnGap: "0.5rem",
            backgroundColor: "transparent",
            marginLeft: "2rem",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "mailto:gerardanarumi@gmail.com";
          }}
        >
          <FaEnvelope
            style={{
              backgroundColor: "transparent",
              fontWeight: "500",
              fontSize: "1.5rem",
              color: "black",
            }}
          />
          <p
            style={{
              color: "black",
              fontSize: "1.5rem",
              fontWeight: "500",
              lineHeight: "20px",
              marginTop: "3px",
              backgroundColor: "transparent",
            }}
          >
            gerardanarumi@gmail.com
          </p>
        </div>
      </div>
    </>
  );
};

export default Service;

import "./Footer.css";
import { FaInstagram, FaYoutube, FaMedium, FaSpotify } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="mt-5 shadow p-3">
      <div className="footer-content">
        <div
          className="container mb-5"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <div
            className="row mt-3"
            style={{
              backgroundColor: "transparent",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
            <div className="col" style={{ backgroundColor: "transparent" }}>
              <a
                className="social"
                href="https://www.instagram.com/gerardamnk/"
                target="_blank"
                style={{ backgroundColor: "transparent" }}
              >
                <FaInstagram />
              </a>
            </div>
            <div className="col" style={{ backgroundColor: "transparent" }}>
              <a
                className="social"
                href="https://www.youtube.com/@gerardamayella"
                target="_blank"
                style={{ backgroundColor: "transparent" }}
              >
                <FaYoutube />
              </a>
            </div>
            <div className="col" style={{ backgroundColor: "transparent" }}>
              <a
                className="social"
                href="https://medium.com/@gerardanarumi"
                target="_blank"
                style={{ backgroundColor: "transparent" }}
              >
                <FaMedium />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import Object from "./Object";
import Profile from "../../assets/profile.jpg";
const Event = () => {
  return (
    <>
      <div className="mb-5">
        <div className="row">
          <h3 style={{ textAlign: "start" }}>Event Organizer</h3>
        </div>
        <div className="row mt-3">
          <Object
            image={Profile}
            title="A la française"
            desc={
              'Organized the leading online conference, "Berkaca dari Renaissance Wabah Pes, Pandemi Covid-19, dan Humanisme", with 250+ participants.'
            }
          />
          <Object
            image={Profile}
            title="Menjadi Pionir Inklusivitas: Implementasi Etika Pola Komunikasi dengan Difabel"
            desc={
              "Conducted a seminar to promote inclusivity in Universitas Gadjah Mada with the staff of the Strategic Studies Department from UKM Peduli Difabel."
            }
          />
        </div>
      </div>
    </>
  );
};

export default Event;

import { Accordion, AccordionHeader } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import "./Resume.css";
const Resume = () => {
  return (
    <>
      <div className="container mt-5 parent">
        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-6 mb-5">
            <h3 style={{ textAlign: "start" }}>Résumé</h3>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="1">
                <AccordionHeader>
                  Service of culture and communication @IFI Yogyakarta
                </AccordionHeader>
                <AccordionBody>
                  <p style={{ textAlign: "justify" }}>
                    Institut Français Yogyakarta is a French Public Institution
                    under the French Foreign Ministry that focuses on promoting
                    French culture and language. As an intern, I helped them
                    manage the distribution of events publications by
                    maintaining an effective editorial calendar of content for
                    all social media channels to ensure timely and relevant
                    posting for their Instagram account. In order to promote
                    cultural activities and services, I crafted creative content
                    that reached more than 300k spectators on Instagram.
                    Moreover, I translated documents, handled live reports, and
                    participated in “Semaine de la Francophonie” as a jury for a
                    storytelling competition (concours lecture à voix hautes des
                    contes) for high school students.
                  </p>
                </AccordionBody>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <AccordionHeader>
                  French language tutor @ french language and literature
                  department
                </AccordionHeader>
                <AccordionBody>
                  <p style={{ textAlign: "justify" }}>
                    From February until June 2023, I assisted 4 French Language
                    and Literature department freshmen to learn intermediate
                    French (B1) by practicing speaking, reading, listening, and
                    writing.
                  </p>
                </AccordionBody>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <AccordionHeader>
                  Head of Strategic studies @UKM Peduli Difabel UGM
                </AccordionHeader>
                <AccordionBody>
                  <p style={{ textAlign: "justify" }}>
                    I was honored to be the head of Strategic Studies at UKM
                    Peduli Difabel as I am able to work with enthusiastic staff
                    who are eager to promote inclusivity in Universitas Gadjah
                    Mada. As the head of the department, I am in charge of
                    organizing and supervising yearly activities and meetings,
                    such as workshops for content writing and socializing
                    etiquette for communicating with disabled people, and also
                    writing articles and various content for Instagram.
                    Moreover, I assisted physically handicapped exam takers
                    during the computer-based written examination (UTBK) and
                    hosted SAUDC Talk podcast which talks about autism in 2022.
                  </p>
                </AccordionBody>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <AccordionHeader>
                  Content writer and copywriter @ arcet
                </AccordionHeader>
                <AccordionBody>
                  <p style={{ textAlign: "justify" }}>
                    My journey started in 2020 when I did the copywriting for
                    Arcet, a production house founded by a group aspiring people
                    who want to explore new things during the pandemic. At
                    first, I conducted Instagram posts for @ Sophist.id which
                    covered various topics during the 2020 Covid-19 pandemic.
                    Later on, I did copywriting to build brand awareness and
                    promotions for several clients.{" "}
                  </p>
                </AccordionBody>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mb-5">
            <h3>Education</h3>
            <Accordion defaultActiveKey="6">
              <Accordion.Item eventKey="5">
                <AccordionHeader>
                  French Language and Literature, Universitas Gadjah Mada
                </AccordionHeader>
                <AccordionBody>
                  <p style={{ textAlign: "justify" }}>
                    I broadened my horizons of multicultural studies, history
                    and art, gender studies, and critical discourse analysis.
                    During the lectures, discussions, and mini-projects, I
                    learned how to apply my knowledge of linguistics, language,
                    and literature for journalism, tourism, and office
                    communication.
                  </p>
                </AccordionBody>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <AccordionHeader>
                  Dynamics of Indonesian Economy, Social, and Politics, SMU-UGM
                  Winter Course Program
                </AccordionHeader>
                <AccordionBody>
                  <p style={{ textAlign: "justify" }}>
                    Through this short program, I’ve obtained new insight into
                    how Indonesia managed to survive and bounce back after the
                    COVID-19 pandemic. On top of that, I got to connect and work
                    together in a group with students from Singapore Management
                    University for a final project presentation.
                  </p>
                </AccordionBody>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <AccordionHeader>
                  Pangudi Luhur Van Lith Senior High School
                </AccordionHeader>
                <AccordionBody>
                  <p style={{ textAlign: "justify" }}>
                    The journey of stepping out of my comfort zone began in a
                    small region in Magelang called Muntilan. I used to be a
                    part of a school radio team, school orchestra, and dance
                    club that participated in several competitions such as DBL,
                    and also, edited and directed my batch self-published
                    reflection book (Lelakon and PER(BEDA)AN).
                  </p>
                </AccordionBody>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;

import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import NavigationBar from "./component/Navbar";
import Footer from "./component/Footer";
import Service from "./page/Service";
import Skill from "./page/Skill";
import Contact from "./page/Contact";
import Resume from "./page/Resume";
import Portofolio from "./page/Portofolio";
import { useState } from "react";
function App() {
  const [page, setPage] = useState(1);
  return (
    <div className="App">
      <NavigationBar page={setPage} />
      <div className="body">
        {page == 1 ? (
          <>
            <Home />
            <Service />
            <Skill />
            <Contact />
          </>
        ) : (
          <>
            {page == 2 ? (
              <>
                <Resume />
              </>
            ) : (
              <>
                <Portofolio />
              </>
            )}
          </>
        )}
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default App;
